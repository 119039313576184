import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#0275d8',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF1616',
        info: '#2196F3',
        success: '#03b300',
        warning: '#ff7000',

        danger: '#FF1616',

        yellow: '#fffb00',
        red: '#FF1616',
        green: '#03b300',
        blue: '#4402cf',
        white: '#ffffff',
        Gris: '#737373',
        Negro: '#000000',
        Morado: '#73008F',
      },
    },
  },
});
