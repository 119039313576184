<template>
    <div><!--
        <v-alert 
            class="mt-3"
            v-show="Alerta.visible == true"
            :type="Alerta.error ? 'error' : 'success'" 
            >
            {{Alerta.mje}}
        </v-alert>-->
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
export default ({

    watch:{
      Alerta: function(value){
        setTimeout( () => {this.OcultarAlerta()}, value.tiempo)
      },
    },

    methods: {
      ...mapMutations(['OcultarAlerta']),
    },

    computed: {
      ...mapState(['Alerta']),
    }

})

</script>