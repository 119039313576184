<template>
<div v-if="usuarioAutenticado">
    <v-app-bar dense app class="warning"> 

        <v-app-bar-nav-icon @click.stop="CambiarNavdrawer"></v-app-bar-nav-icon>
        
        <v-toolbar-title 
          @click="home()"
          style="cursor: pointer"
        >
          Dilibit
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          elevation="10" 
          color="warning"
         @click="MostrarCotizador()"
        >
          COTIZADOR
          <v-icon
            right
          >
            mdi-text-search
          </v-icon>
        </v-btn>


    <v-spacer></v-spacer>

    <v-col cols="auto">
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-tab>
            <v-btn
                v-bind="attrs"
                v-on="on"
                 fab
                 small
                 color="warning"
                 @click="ConsultarNotificaciones"
            >
            <v-badge
              :color="CantidadNotificaciones > 0 ? 'success': ''"
              :content="CantidadNotificaciones"
              overlap
            >
              <v-icon 
              medium
              >
                mdi-bell
              </v-icon>
            </v-badge>
            </v-btn>
          </v-tab>
        </template>
        
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
              color="primary"
              dark
            > Tienes {{CantidadNotificaciones}} notificaciones
            <v-spacer></v-spacer>
            <v-btn 
              elevation="5" 
              text 
              small 
              fab 
              style="cursor: pointer" 
              @click="dialog.value = false"
            >
              X
            </v-btn>
            </v-toolbar>
            
            <v-card-text>
              <div v-for="(item, index) in NotificacionesMenu.items" :key="index">

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="mt-2">
                      <div 
                        v-bind="attrs"
                        v-on="on"
                      >
                      <v-btn
                        icon
                        color="success"
                        @click="AceptarItem(item)"
                      >
                        <v-icon>mdi-check</v-icon>
                        
                      </v-btn>
                     <v-btn text class="text-h6 ml-n3" @click="AceptarItem(item)"><strong>{{item.id}}. {{item.titulo}}</strong></v-btn>
                     </div>
                    <div class="text-h8 pa-1 text-justify">{{item.mje}}</div>
                    </div>
                  </template>
                <span>CLICK PARA ACEPTAR MENSAJE # {{item.id}}</span>
              </v-tooltip>
               
              </div>
              <h1 v-if="NotificacionesMenu.length == 0" class="mt-10" >No tienes notificaciones para leer.</h1>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                color="error"
                @click="dialog.value = false"
              >Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>

        <v-toolbar-items class="hidden-sm-and-down">
          
           <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    v-bind="attrs"
                    v-on="on"
                  >
                  {{ Disponible }}
                  <v-icon>mdi-cash</v-icon>
                  </v-btn>
              </template>
            <span>DISPONIBLE</span>
          </v-tooltip>
          
          <v-divider vertical></v-divider>

          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    v-bind="attrs"
                    v-on="on"
                    @click="DialogComision = true"
                  >
                  {{ Comision }}
                  <v-icon>mdi-gamepad-circle</v-icon>
                  </v-btn>
              </template>
            <span>COMISIÓN</span>
          </v-tooltip>
          
          <v-divider vertical></v-divider>

          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    v-bind="attrs"
                    v-on="on"
                  >
                  {{ Puntos }}
                  <v-icon>mdi-record</v-icon>
                  </v-btn>
              </template>
            <span>PUNTOS</span>
          </v-tooltip>
          
          <v-divider vertical></v-divider>

          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                  <v-btn 
                  v-bind="attrs"
                    v-on="on"
                  @click="CerrarSession()"
                  class="error" text
                  >
                    Salir
                  </v-btn>
              </template>
            <span>SALIR DEL SISTEMA</span>
          </v-tooltip>

         

          <v-divider vertical></v-divider>
        </v-toolbar-items>
      <!-- Menú punticos -->
            <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              text
                v-bind="attrs"
                v-on="on"
                class="d-sm-flex d-md-none"
              >
                    <v-icon 
                    medium
                    >
                      mdi-dots-vertical
                    </v-icon>
              </v-btn>
            </template>
            <v-list>

              <v-list-item dense>
                <v-list-item-title>Disponible: {{ Disponible }}</v-list-item-title>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item dense>
                <v-list-item-title @click="DialogComision = true">Comisión: {{ Comision }}</v-list-item-title>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item dense>
                <v-list-item-title>Puntos: {{ Puntos }}</v-list-item-title>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item dense>
                <v-list-item-title class="text-center">
                  <v-btn text color="error" @click="CerrarSession">
                    Salir
                  </v-btn>
                </v-list-item-title>
              </v-list-item>

            </v-list>
          </v-menu>
          <!-- Menú punticos -->
   </v-app-bar>

   <v-dialog
      v-model="tokenexpiredGetter"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          La sesión a Expirado
        </v-card-title>
        <v-card-text>Ingresa la contraseña de inicio de sesión para continuar.</v-card-text>
        <v-form
          v-on:submit.prevent="consultarepiredtoken()"
          ref="form"
          lazy-validation
        >
         <v-col
          cols="12"
        >
          <v-text-field
            :type="tipopassword ?  'text' : 'password'"
            v-model="datostokenexpired.pass"
            label="Contraseña"
            hint="Escribe la misma contraseña de inicio de sesión."
            persistent-hint
            :rules="PassRules"
            :loading="datostokenexpired.pass == '' ? true : false"
            required
          ></v-text-field>

          <v-checkbox
          class="mt-n1"
            v-model="tipopassword"
            label="Ver Contraseña"
          ></v-checkbox>
        </v-col>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
             @click="IrALogin()"
            requerid
          >
            Cancelar
          </v-btn>
          <v-btn
            type="submit"
            color="primary"
            @click="validate"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
       </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="DialogComision"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Comisión a Disponible
        </v-card-title>
        
        <div v-if="DatosdeUsuario.perfil == 3" class="mx-auto text-center">
          <v-card-text v-if="this.DatosPuntoDeVenta.comision == 0">No posee comisión para trasladar a disponible.</v-card-text>
          <v-card-text v-else>Click en aceptar para pasar dinero de comisión a dinero disponible.</v-card-text>
        </div>

        <v-col cols="12">
          <h1 v-if="DatosdeUsuario.perfil == 3" class="text-center">{{Comision}}</h1>
          <v-card-text v-else class="text-center">
            Solo el administrador del punto de venta tiene permiso de trasladar comisión a disponible.
          </v-card-text>
         </v-col>

        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
             @click="DialogComision = false"
            requerid
          >
            Cancelar
          </v-btn>

          <v-btn
            v-if="this.DatosPuntoDeVenta.comision > 0 && this.DatosdeUsuario.perfil == 3"
            color="primary"
            @click="ComisionaDiosponible()"
          >
            Aceptar
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

<v-dialog
  v-model="DialogCotizador"
  max-width="800"
>
  <v-card
    class="mx-auto mt-12"
    max-width="470"
  >
    <v-system-bar></v-system-bar>

    <v-toolbar
      flat
      color="transparent"
    >
      <v-btn 
        icon
        @click="DialogCotizador = false"
        color="error"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-text-field
        v-model="searchcotizador"
        append-icon="mdi-magnify"
        label="Digita el nombre del producto a buscar"
        single-line
      ></v-text-field>
    </v-toolbar>

    <v-list three-line>
      <v-list-item
        v-for="(item, i) in itemscotizador"
        :key="i"
        ripple
        @click="() => {}"
      >
        <v-img
          class="mr-4"
          max-width="64"
          min-width="64"
          :src="item.img_principal"
        ></v-img>

        <v-list-item-content>
          <h4 v-text="item.nombre+' '+item.duracion+ ' DÍAS '+item.tipo_cuenta+' PANTALLAS'"></h4>
            <p>Mi Costo : {{item.precio_c}}</p>
            <p>Sugerido de Venta : {{item.precio_pv}}</p>
          <div v-text="item.title"></div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</v-dialog>


</div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'

    export default {
      created(){
        this.ConsultarNotificaciones()
        this.ConsultarProductosStreamingFormat()
      },

      data() {
        return {
          tipopassword: false,
          searchcotizador: '',
          itemscotizador: [],
          DialogCotizador: false,
          DialogComision: false,
          datostokenexpired: {id: null, pass: null},
          closeOnClick: true,
          CantidadNotificaciones: 0,

          PassRules: [
            v => !!v || 'Contraseña Requerida',
            v => /^[@]+/.test(v) || 'Contraseña debe comenzar con @',
            v => (v && v.length >= 8) || 'Contraseña debe tener al menos 8 caracteres',
            v => /[A-Z]+/.test(v) || 'La contraseña debe tener al menos 1 letra mayuscula.',
            v => /[a-z]+/.test(v) || 'La contraseña debe tener al menos 1 letra minuscula.',
            v => /[0-9]+/.test(v) || 'La contraseña debe contener al menos 1 número.',
          ],
        }
      },

      methods:{
        ...mapMutations(['CambiarNavdrawer']),
        ...mapActions(['CerrarSession', 'ConsultarNotificaciones','IrALogin','ConsultarTokenExpiredPopup',
                       'ComisionaDiosponible','NotificacionAceptada','ConsultarProductosStreamingFormat']),

        validate () {
          this.$refs.form.validate()
        },

        home () {
          this.$router.push('/').catch(()=>{});
        },

        consultarepiredtoken () {
          this.datostokenexpired.id = this.DatosdeUsuario.id
          this.ConsultarTokenExpiredPopup(this.datostokenexpired)
        },

        AceptarItem(item){
          var data = Object.assign({}, item)
          this.NotificacionAceptada(data);
        },

        MostrarCotizador(){
          this.ConsultarProductosStreamingFormat()
          if(this.searchcotizador == ''){
            this.itemscotizador = this.ProductosStreamingGetterFormat.items
          }
          this.DialogCotizador = true
        }
        
      },

      watch:{
        NotificacionesMenu:function(value){
          if(!!value){
            if(value.items.length > 0){
              this.CantidadNotificaciones = value.items.length
            }else{
              this.CantidadNotificaciones = 0
            }
          }
        },

        searchcotizador: function(value){
          this.searchcotizador = value.toUpperCase()
        },

        searchcotizador: function(value){
          if (this.searchcotizador == '' && this.ProductosStreamingGetterFormat)
              this.itemscotizador = this.ProductosStreamingGetterFormat.items
         
          const search = value.toUpperCase()

          this.itemscotizador = this.ProductosStreamingGetterFormat.items.filter(item => {
            var busq = item.nombre+' '+item.duracion+ ' DÍAS '+item.tipo_cuenta+' PANTALLAS'
            return busq.indexOf(search) > -1
          })
        }
      },

      computed:{
        ...mapGetters(['usuarioAutenticado','DatosdeUsuario','DatosPuntoDeVenta','NotificacionesMenu',
                       'tokenexpiredGetter','ProductosStreamingGetterFormat']),

        Disponible: function(){
          var respuesta = this.DatosPuntoDeVenta.disponible
            if(respuesta){
              respuesta = '$'+respuesta.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."); 
               return respuesta
            }
        },

        Comision: function(){
          var valor = this.DatosPuntoDeVenta.comision
            if(valor >= 0){
              var respuesta = '$'+valor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."); 
               return valor == 0 ? '$0.00' : respuesta 
            }
        },
        
        Puntos: function(){
          var respuesta = this.DatosPuntoDeVenta.puntos
            if(respuesta){
              respuesta = respuesta.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."); 
               return respuesta
            }
        },

      }
    };
</script>