import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import rutas from "@/config.js"
import router from "../router"
import billeteras from './billeteras'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barerAuth: 'Barer',

    Alerta: {visible: false, error: false, mje: ''},

    MensajeEps: false,

    ProgressCircular: false,

    SINO:[
      {id: 1, titulo:'SI'},
      {id: 0, titulo:'NO'}
    ],

    SEXO:[
      {id: 1, titulo:'MASCULINO'},
      {id: 2, titulo:'FEMENINO'}
    ],

    TipoDocumento:[
      {id: 1, titulo:'1. Cedula Ciudadanía'},
      {id: 2, titulo:'2. Cedula Extrangería'},
      {id: 3, titulo:'3. Permiso especial permanencia'},
      {id: 4, titulo:'4. Pasaporte'}
    ],

    EstadoCivil:[
      {id: 1, titulo:'1. Soltero'},
      {id: 2, titulo:'2. Casado'},
      {id: 3, titulo:'3. Unión Libre'},
    ],

    ActividadEconomica:[
      {id: 1, titulo:'1. Auxiliar Oficina - Almacen'},
      {id: 2, titulo:'2. Técnico'},
      {id: 3, titulo:'3. Operario - Mantenimiento'},
      {id: 4, titulo:'4. Conductor - Mensajeria'},
      {id: 5, titulo:'5. Obrero'},
    ],

    EPS:[
      {id: 1, titulo:'1. Aliansalud'},
      {id: 2, titulo:'2. Capital Salud EPS-S'},
      {id: 3, titulo:'3. Comfenalco Valle del Cauca'},
      {id: 4, titulo:'4. Coomeva'},
      {id: 5, titulo:'5. Coosalud'},
      {id: 6, titulo:'6. Cruz Blanca'},
      {id: 7, titulo:'7. Famisanar de Cafam y Colsubsidio'},
      {id: 8, titulo:'8. Mutual SER'},
      {id: 9, titulo:'9. Nueva EPS'},
      {id: 10, titulo:'10. S.O.S'},
      {id: 11, titulo:'11. Salud Compensar'},
      {id: 12, titulo:'12. Salud Total'},
      {id: 13, titulo:'13. Salud Vida'},
      {id: 14, titulo:'14. Sanitas'},
      {id: 15, titulo:'15. SURA'},
    ],

    ARL:[
      {id: 1, titulo:'1. ARL Sura'},
      {id: 2, titulo:'2. ARL Positiva'},
      {id: 3, titulo:'3. ARL Axa Colpatria'},
      {id: 4, titulo:'4. ARL Colmena'},
      {id: 5, titulo:'5. ARL Bolívar'},
      {id: 6, titulo:'6. ARL Liberty'},
      {id: 7, titulo:'7. ARL La Equidad'},
    ],

    CAJA:[
      {id: 0, titulo:'Sin Caja'},
      {id: 1, titulo:'1. Comfama (Antioquia)'},
      {id: 2, titulo:'2. Comfenalco (Antioquia)'},
      {id: 3, titulo:'3. Comfiar (Arauca)'},
      {id: 4, titulo:'4. Cajacopi (Atlántico)'},
      {id: 5, titulo:'5. Combarranquilla (Atlántico)'},
      {id: 6, titulo:'6. Comfamiliar (Atlántico)'},
      {id: 7, titulo:'7. Cafam (Cundinamarca)'},
      {id: 8, titulo:'8. Colsubsidio (Cundinamarca)'},
      {id: 9, titulo:'9. Compensar (Cundinamarca)'},
      {id: 10, titulo:'10. Comfamiliar (Bolivar)'},
      {id: 11, titulo:'11. Comfenalco (Bolivar)'},
      {id: 12, titulo:'12. Comfaboy (Boyacá)'},
      {id: 13, titulo:'13. Confa (Caldas)'},
      {id: 14, titulo:'14. Comfaca (Caquetá)'},
      {id: 15, titulo:'15. Comfacasanare (Casanare)'},
      {id: 16, titulo:'16. Comfacauca (Cauca)'},
      {id: 17, titulo:'17. Comfacesar (Cesar)'},
      {id: 18, titulo:'18. Comfachocó (Chocó)'},
      {id: 19, titulo:'19. Comfacor (Córdoba)'},
      {id: 20, titulo:'20. Comfacundi (Cundinamarca)'},
      {id: 21, titulo:'21. Comfaguajira (Guajira)'},
      {id: 22, titulo:'22. Comfamilair (Huila)'},
      {id: 23, titulo:'23. Cajamag (Magdalena)'},
      {id: 24, titulo:'24. Cofrem (Meta)'},
      {id: 25, titulo:'25. Comfaoriente (Norte de Santander)'},
      {id: 26, titulo:'26. Comfanorte (Norte de Santander)'},
      {id: 27, titulo:'27. Comfamiliar (Putumayo)'},
      {id: 28, titulo:'28. Comfenalco (Quindio)'},
      {id: 29, titulo:'29. Cajasai (San Andrés y Providencia)'},
      {id: 30, titulo:'30. Cafaba (Santander)'},
      {id: 31, titulo:'31. Cajasan (Santander)'},
      {id: 32, titulo:'32. Comfenalco (Santander)'},
      {id: 33, titulo:'33. Comfasucre (Sucre)'},
      {id: 34, titulo:'34. Comfatolima (Tolima)'},
      {id: 35, titulo:'35. Cafasur (Tolima)'},
      {id: 36, titulo:'36. Comfandi (Valle del Cauca)'},
      {id: 37, titulo:'37. Comfenalco (Valle del Cauca)'},
    ],

    PENSION:[
      {id: 0, titulo:'Sin Pensión'},
      {id: 1, titulo:'1. AFP Protección'},
      {id: 2, titulo:'2. AFP Porvenir'},
      {id: 3, titulo:'3. AFP Colpensiones'},
      {id: 4, titulo:'4. AFP Colfondos'},
      {id: 5, titulo:'5. AFP Skandia'},
    ],

    DEPARTAMENTOS:[],
    CIUDADES:[],
    
    PuntosDeVenta: [],
    UsuariosEps: [],
    valorEps: 0,
    Documentos: [],
    ParametrosEps: [],
    Perfiles: null,
    token: null,
    UsuarioLogueado:null,
    tokenexpired: false,
    
    PuntoDeVenta: [],
    DatosLogin:[],
    Navdrawer: null,
    Menu: [],
    Notificaciones: [],
    NotificacionesTabla: [],

    /*Usuarios*/
    Usuarios: null,

    /*Clientes */
    Clientes: null,

    /*Productos Streaming*/
    ProductosStreaming: null,
    ProductosStreamingFormat: null,
    DatosStreaming: {plural: ''},

    /*Codigos Streaming*/
    CodigosStreaming: null,

    /*Parametros Streaming*/
    ParametrosStreaming: null,

    /*Ventas Eps*/
    VentasEps: null,

    /*Ventas*/
    Ventas: null,

    /*Recargas*/
    Recargas: null,

    /*Venta Streaming*/
    PlataformasStreaming: [],
    DuracionStreaming: [],
    TipodeCuentaStreaming: [],
    ClienteVenta: '',
    PreciosStreaming: null,
    VentaStreaming: {mje: 'REALIZAR VENTA*', error: true},
  },

  getters: {
    Alerta(state){
      state.Alerta
    },

    SINO(state){
      return state.SINO
    },

    SEXO(state){
      return state.SEXO
    },

    TipoDocumento(state){
      return state.TipoDocumento
    },

    EstadoCivil(state){
      return state.EstadoCivil
    },

    ActividadEconomica(state){
      return state.ActividadEconomica
    },

    EPS(state){
      return state.EPS
    },

    ARL(state){
      return state.ARL
    },

    CAJA(state){
      return state.CAJA
    },

    PENSION(state){
      return state.PENSION
    },

    DEPARTAMENTOS(state){
      return state.DEPARTAMENTOS
    },

    CIUDADES(state){
      return state.CIUDADES
    },

    DatosPuntoDeVenta(state){
      return state.PuntoDeVenta
    },

    PuntosDeVentaGetter(state){
      return state.PuntosDeVenta
    },

    UsuariosEpsGetter(state){
      return state.UsuariosEps
    },

    valorEps(state){
      return state.valorEps
    },

    PerfilesGetter(state){
      return state.Perfiles
    },

    DatosdeUsuario(state){
      return state.UsuarioLogueado.usuario
    },

    usuarioAutenticado(state){
      return !!state.UsuarioLogueado
    },

    ValorNavdrawer(state){
      return state.Navdrawer
    },

    tokenexpiredGetter(state){
      return state.tokenexpired
    },

    /*Notificaciones*/
    NotificacionesMenu(state){
      return state.Notificaciones
    },

    NotificacionesTablaGetter(state){
      return state.NotificacionesTabla
    },

    /*Usuarios */
    UsuariosGetter(state){
      return state.Usuarios
    },

    /*Clientes*/
    ClientesGetter(state){
      return state.Clientes
    },

    /*Productos Streaming*/
    ProductosStreamingGetter(state){
      return state.ProductosStreaming
    },

    /*EPS*/
    ParametrosEps(state){
      return state.ParametrosEps
    },

    ProductosStreamingGetterFormat(state){
      return state.ProductosStreamingFormat
    },

    DatosStreamingGetter(state){
      return state.DatosStreaming
    },

    /*Codigos Streaming*/
    CodigosStreamingGetter(state){
      return state.CodigosStreaming
    },

    /*Parametros Streaming*/
    ParametrosStreamingGetter(state){
      return state.ParametrosStreaming
    },
    
    /*VentasEps*/
    VentasEpsGetter(state){
      return state.VentasEps
    },

    /*Ventas*/
    VentasGetter(state){
      return state.Ventas
    },

    /*Recargas*/
    RecargasGetter(state){
      return state.Recargas
    },

    /*Ventas Streaming*/
    PlataformasStreamingGetter(state){
      return state.PlataformasStreaming
    },

    VentaStreamingGetter(state){
      return state.VentaStreaming
    }
  },

  mutations: {
    VisualizarProgressCircular(state){
      state.ProgressCircular = true
    },

    OcultarProgressCircular(state){
      state.ProgressCircular = false
    },

    OcultarAlerta(state){
      state.Alerta.visible = false
      state.Alerta.error = false
      state.Alerta.mje = ''
    },

    LlenarDepartamentos(state, data){
      state.DEPARTAMENTOS = data
    },

    LlenarCiudades(state, data){
      state.CIUDADES = data
    },

    LLenarAlerta(state, data){
      state.Alerta = data.alerta
    },

    LLenarMensajeEps(state){
      state.MensajeEps = true
    },

    CerrarMensajeEps(state){
      state.MensajeEps = false
    },

    LlenarPuntosDeVenta(state, data){
      state.PuntosDeVenta = data
    },

    LlenarUsuariosEps(state, data){
      state.UsuariosEps = data
    },

    LlenarValorSeguridadSocial(state, data){
      state.valorEps = data
    },

    LlenarDocumentosSeguridadSocial(state, data){
      state.Documentos = data
    },

    LlenarPerfiles(state, data){
      state.Perfiles = data
    },

    CargarMenu(state, data){
      state.Menu = data;
    },

    setToken(state, data){
      state.token = data;
    },

    RespuestaPuntoDeVenta(state, data){
        state.PuntoDeVenta = data;
      },

    RespuestaLogin(state, data){
      state.UsuarioLogueado = data;
    },

    UsuarioLogueadoNull(state){
      state.UsuarioLogueado = null
    },

    RespuestaAutenticarLogin(state, data){
      state.DatosLogin = data;
    },

    CambiarNavdrawer(state){
      state.Navdrawer = !state.Navdrawer;
    },

    LlenarNotificaciones(state, data){
      state.Notificaciones = data;
    },

    LlenarNotificacionesTabla(state, data){
      state.NotificacionesTabla = data;
    },

    PopUPTokenexpiredTrue(state){
      state.tokenexpired = false
    },

    PopUPTokenexpired(state){
      state.tokenexpired = true
    },

    /*Usuarios */
    LlenarUsuarios(state, data){
      state.Usuarios = data
    },

    /*Clientes*/
    LlenarClientes(state, data){
      state.Clientes = data
    },

    /*Productos Streaming*/
    LlenarProductosStreaming(state, data){
      state.ProductosStreaming = data
    },

    LlenarProductosStreamingFormat(state, data){
      state.ProductosStreamingFormat = data
    },

    LlenarDatosStreaming(state, data){
      state.DatosStreaming = data
    },

    /*Codigos Streaming*/
    LlenarCodigosStreaming(state, data){
      state.CodigosStreaming = data
    },

    /*Parametros Streaming*/
    LlenarParametrosStreaming(state, data){
      state.ParametrosStreaming = data
    },

    /*EPS*/
    LlenarParametrosEps(state, data){
      state.ParametrosEps = data
    },

    /*VentasEps*/
    LlenarVentasEps(state, data){
      state.VentasEps = data
    },


    /*Ventas*/
    LlenarVentas(state, data){
      state.Ventas = data
    },

    /*Recargas*/
    LlenarRecargas(state, data){
      state.Recargas = data
    },

    /*Venta Streaming*/
    LlenarPlataformasStreaming(state, data){
      state.PlataformasStreaming = data;
    },

    LlenarDuracionStreaming(state, data){
      state.DuracionStreaming = data;
    },

    LlenarTipodeCuentaStreaming(state, data){
      state.TipodeCuentaStreaming = data;
    },

    LlenarClienteCelular(state, data){
      state.ClienteVenta = data;
    },

    LlenarPreciosStreaming(state, data){
      state.PreciosStreaming = data;
    },

    LlenarVenderStreaming(state, data){
      state.VentaStreaming = data;
    },
  },

  actions: {
    /*Nav*/
    ComisionaDiosponible: async function ({ dispatch, commit, state }){
      try{
        var pv = state.PuntoDeVenta.id
        var datos = { pv }
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'aut/comisionadisponible';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
          commit('LlenarPuntosDeVenta', json);
          setTimeout(() => { dispatch('ConsultarPuntoDeVenta') }, 500);
        }
      }catch(err){
        console.log(err)
      }
    },

    /*EPS */
    ConsultarUsuariosEps: async function ({ commit, state }){
      try{
        const settings = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'eps/';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LlenarUsuariosEps', json);
        }
      }catch(err){
        console.log(err)
      }
    },

    SubirArchivos: function ({ commit, state }, datos){
      try{
        const CLOUDINARY_URL = rutas.backend+'subirdocumentos';
        const img = datos.file;
        img.forEach(function(element) {
          var file = element;
          const formData = new FormData();
          formData.append('file', file);
          formData.append('data', datos);
          axios.post(
                CLOUDINARY_URL,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                }
            );
        });
      }catch(err){
        console.log(err)
      }
    },

    SubirArchivos2: function ({ commit, state }, datos){
      try{
        const img = datos.file;
        img.forEach(function(element) {
          var file = element;
          const formData = new FormData();
          formData.append('file', file);
          formData.append('data', datos);
          const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': state.barerAuth,
                'x-access-token': state.token
            },
            body: formData
          };

          const url = rutas.backend+'subirdocumentos';
          fetch(url, settings);
        });
      }catch(err){
        console.log(err)
      }
    },

    NuevoUsuarioEps: async function ({ dispatch, commit, state }, datos){
      try{
        commit('VisualizarProgressCircular');
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };

        const url = rutas.backend+'eps/nuevo';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          //commit('LLenarAlerta', json)
          commit('LLenarMensajeEps', json)
          setTimeout(() => { dispatch('ConsultarPuntoDeVenta') }, 500);
        }
        commit('OcultarProgressCircular');
      }catch(err){
        console.log(err)
      }
    },

    EliminarUsuarioEps: async function ({ commit, state }, datos){ 
      try{
        const settings = {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'eps/'+datos.id;
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
    },

    RenovarUsuarioEps: async function ({ dispatch, commit, state }, datos){ 
      try{
        commit('VisualizarProgressCircular');
        const settings = {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'eps/renovar';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
          if(json.status == 204){
            await dispatch('ConsultarUsuariosEps')
            setTimeout(() => { dispatch('ConsultarPuntoDeVenta') }, 500);
          }
          commit('OcultarProgressCircular');
        }
      }catch(err){
        console.log(err)
      }
    },

    ConsultarValorPagar: async function ({ commit, state }, datos){
      try{
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'eps/valorpagar';
        const data = await fetch(url, settings);
        const json = await data.json();
        var valor = 0
        if(json.valor != 0){
          valor = json.valor
        }
        commit('LlenarValorSeguridadSocial', valor);
      }catch(err){
        console.log(err)
      }
    },

    ConsulDocumentosEps: async function ({ commit, state }, datos){
      try{
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'subirdocumentos/mostrardocumentos';
        const data = await fetch(url, settings);
        const json = await data.json();
        commit('LlenarDocumentosSeguridadSocial', json);
      }catch(err){
        console.log(err)
      }
    },

    ConsultarParametrosEps: async function ({ commit, state }){
      try{
        const settings = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'parametroseps/';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LlenarParametrosEps', json);
        }
      }catch(err){
        console.log(err)
      }
    },

    NuevoParametrosEps: async function ({ commit, state }, datos){ 
      try{
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };

        const url = rutas.backend+'parametroseps/nuevo';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
    },


    EliminarParametrosEps: async function ({ commit, state }, datos){ 
      try{
        const settings = {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'parametroseps/'+datos.id;
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
    },

    ConsultarDepartamentos: async function ({ commit, state }){
      try{
        const settings = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'general/departamentos';
        const data = await fetch(url, settings);
        const json = await data.json();
        commit('LlenarDepartamentos', json.dpto);
      }catch(err){
        console.log(err)
      }
    },

    ConsultarCiudadesxDpto: async function ({ commit, state }, datos){
      try{
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'general/ciudades';
        const data = await fetch(url, settings);
        const json = await data.json();
        commit('LlenarCiudades', json.municipios);
      }catch(err){
        console.log(err)
      }
    },

    /*Puntos de venta */
    ConsultarPuntosDeVenta: async function ({ commit, state }){
      try{
        const settings = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'puntosdeventa/';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LlenarPuntosDeVenta', json);
        }
      }catch(err){
        console.log(err)
      }
    },

    NuevoPuntodeVenta: async function ({ commit, state }, datos){ 
      try{
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };

        const url = rutas.backend+'puntosdeventa/nuevo';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
    },

    EliminarPuntodeVenta: async function ({ commit, state }, datos){ 
      try{
        const settings = {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'puntosdeventa/'+datos.id;
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
    },

    AnadiraDisponible: async function ({ dispatch, commit, state }, datos){ 
      try{
        const settings = {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'puntosdeventa/recarga';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
          await dispatch('ConsultarPuntoDeVenta')
          setTimeout(() => { dispatch('ConsultarPuntoDeVenta') }, 500);
        }
      }catch(err){
        console.log(err)
      }
    },

    ConsultarPerfiles: async function ({ commit, state }){
      try{
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'perfiles/';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LlenarPerfiles', json);
        }
      }catch(err){
        console.log(err)
      }
    },

    IrALogin: ({ commit}) => {
      commit('PopUPTokenexpiredTrue')
      commit('UsuarioLogueadoNull')
      router.push('/login')
    },


    LoginSistem: async function ({ dispatch, commit, state }, datos){ 
      try{
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'aut/login';
        const data = await fetch(url, settings);
        const json = await data.json();
        const token = json.token;
        commit('setToken', token);
        commit('RespuestaLogin', json);
        localStorage.setItem('token', token);

        commit('LLenarAlerta', json)
        if(token != null){
          setTimeout(() => {  router.push('/') }, 500);
          dispatch('ConsultarPuntoDeVenta')
        }

      }catch(err){
        console.log(err)
      }
    },

    CerrarSession: async function ({ commit, state }, datos){ 
      try{
        const settings2 = {
          method: 'POST',
          headers: {
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'aut/logout';
        const data = await fetch(url, settings2);
        const json = await data.json();
        commit('setToken', null);
        commit('RespuestaLogin', json);
        localStorage.setItem('token', null);
        router.push('/login')
        commit('LLenarAlerta', json)
    }catch(err){
      console.log(err)
    }
  },

    ConsultarPuntoDeVenta: async ({ commit, state}) => {
      if(state.token != null){
        try{
          const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', 
                'Authorization': state.barerAuth,
                'x-access-token': state.token
            },
          };
          const url = rutas.backend+'aut/puntodeventa';
          const data = await fetch(url, settings);
          const json = await data.json();
          commit('RespuestaPuntoDeVenta', json);
        }catch(err){
          console.log(err)
        }
      }
    },
  
    ConsultarTokenExpiredPopup: async ({ commit, state }, datos) => {
        try{
          const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', 
                'Authorization': state.barerAuth,
                'x-access-token': state.token
            },
            body: JSON.stringify(datos)
          };
          const url = rutas.backend+'aut/authpass';
          const data = await fetch(url, settings);
          const json = await data.json();
          commit('LLenarAlerta', json)
          if(json.status == 204){
            const token = json.token;
            commit('setToken', token);
            commit('RespuestaLogin', json);
            commit('PopUPTokenexpiredTrue');
            localStorage.setItem('token', token);
          }else{
            commit('RespuestaLogin', json);
            commit('setToken', null);
            commit('PopUPTokenexpiredTrue')
            router.push('/login')
          }
        }catch(err){
          console.log(err)
        }
    },
  
    ConsultarMenu: async function ({ commit, state }, datos){ 
        try{
          const settings = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', 
                'Authorization': state.barerAuth,
                'x-access-token': state.token
            },
            body: JSON.stringify(datos)
          };
          const url = rutas.backend+'general/menu';
          const data = await fetch(url, settings);
          const json = await data.json();
          commit('CargarMenu', json);
      }catch(err){
        console.log(err)
      }
    },
  
    LeerToken({ commit }){
        if(localStorage.getItem('token')){console.log('si')
          commit('setToken', localStorage.getItem('token'));
        }else{console.log('no')
          commit('setToken', null);
        }
    },

    /*Notificacioens*/
    ConsultarNotificacionesTabla: async function ({ commit, state }){
      try{
        const settings = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'notificaciones/';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LlenarNotificacionesTabla', json);
        }
      }catch(err){
        console.log(err)
      }
    },
    
    NuevoNotificacionesTabla: async ({ commit, state }, datos) => {
      try{
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'notificaciones/nuevo';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
     },

     EliminarNotificacionesTabla: async function ({ commit, state }, datos){ 
      try{
        const settings = {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'notificaciones/'+datos.id;
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
    },

    ConsultarNotificaciones: async function ({ commit, state }){
      try{
        var fecha_registro = state.UsuarioLogueado.usuario.fecha_registro
        var id_usuario = state.UsuarioLogueado.usuario.id
        var datos = {id_usuario,fecha_registro}
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'notificaciones/notificaciones';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LlenarNotificaciones', json);
        }
      }catch(err){
        console.log(err)
      }
    },

    NotificacionAceptada: async function ({ dispatch, commit, state }, datos){
      try{
        
        var id_usuario = state.UsuarioLogueado.usuario.id
        var id_mensaje = datos.id
        var datos = {id_usuario, id_mensaje}

        const settings = {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'notificaciones/aceptarnotificacion';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          setTimeout(() => { dispatch('ConsultarNotificaciones') }, 500);
        }
      }catch(err){
        console.log(err)
      }
    },

    /*Usuarios*/ 
    ConsultarUsuarios: async function ({ commit, state }){
        try{
          const settings = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': state.barerAuth,
                'x-access-token': state.token
            },
          };
          const url = rutas.backend+'usuarios/';
          const data = await fetch(url, settings);
          const json = await data.json();
          if(typeof json.error.expiredAt !== 'undefined'){
            commit('PopUPTokenexpired');
          }else{
            commit('LlenarUsuarios', json);
          }
        }catch(err){
          console.log(err)
        }
      },

     NuevoUsuario: async ({ commit, state }, datos) => {
      try{
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'usuarios/nuevo';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
     },

     EliminarUsuario: async function ({ commit, state }, datos){ 
      try{
        const settings = {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'usuarios/'+datos.id;
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
    },

    ResetUsuario: async function ({ commit, state }, datos){ 
      try{
        const settings = {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'usuarios/reset';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
    },

    CambiarCredenciales: async function ({ commit, state }, datos){ 
      try{
        const settings = {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'usuarios/updatecredenciales';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
          if(json.error == false){
            router.push('/login')
            commit('setToken', null);
          }
        }
      }catch(err){
        console.log(err)
      }
    },


      /*Clientes*/ 
      ConsultarClientes: async function ({ commit, state }){
      try{
        const settings = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'clientes/';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LlenarClientes', json);
        }
      }catch(err){
        console.log(err)
      }
    },

    NuevoCliente: async function ({ commit, state }, datos){ 
      try{
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };

        const url = rutas.backend+'clientes/nuevo';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
   },

   EliminarCliente: async function ({ commit, state }, datos){ 
    try{
      const settings = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': state.barerAuth,
            'x-access-token': state.token
        },
      };

      const url = rutas.backend+'clientes/'+datos.id;
      const data = await fetch(url, settings);
      const json = await data.json();
      if(typeof json.error.expiredAt !== 'undefined'){
        commit('PopUPTokenexpired');
      }else{
        commit('LLenarAlerta', json)
      }
    }catch(err){
      console.log(err)
    }
  },

    /*Productos Streaming*/ 
    ConsultarProductosStreaming: async function ({ commit, state }){
      try{
        const settings = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'productosstreaming/';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LlenarProductosStreaming', json);
        }
      }catch(err){
        console.log(err)
      }
    },

    ConsultarProductosStreamingFormat: async function ({ commit, state }){
      try{
        const settings = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'productosstreaming/mostrartodosformat';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LlenarProductosStreamingFormat', json);
        }
      }catch(err){
        console.log(err)
      }
    },

    NuevoProductosStreaming: async function ({ commit, state }, datos){ 
      try{
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'productosstreaming/nuevo';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
    },

    EliminarProductosStreaming: async function ({ commit, state }, datos){ 
      try{
        const settings = {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'productosstreaming/'+datos.id;
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
    },

    /*Codigos Streaming*/ 
    ConsultarCodigosStreaming: async function ({ commit, state }){
      try{
        const settings = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'codigosstreaming/';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LlenarCodigosStreaming', json);
        }
      }catch(err){
        console.log(err)
      }
    },

    NuevoCodigoStreaming: async function ({ commit, state }, datos){ 
      try{
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'codigosstreaming/nuevo';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
    },

    EliminarCodigoStreaming: async function ({ commit, state }, datos){ 
      try{
        const settings = {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'codigosstreaming/'+datos.id;
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
    },

    SubirCodigosExcel: async function ({ dispatch, commit, state }, datos){ 
      try{
        const settings = {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'codigosstreaming/';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
          await dispatch('ConsultarCodigosStreaming')
        }
      }catch(err){
        console.log(err)
      }
    },

    /*Parametros Streaming*/ 
    ConsultarParametrosStreaming: async function ({ commit, state }){
      try{
        const settings = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'parametrosstreaming/';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LlenarParametrosStreaming', json);
        }
      }catch(err){
        console.log(err)
      }
    },
    
    NuevoParametrosStreaming: async function ({ commit, state }, datos){ 
      try{
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };

        const url = rutas.backend+'parametrosstreaming/nuevo';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
    },

    EliminarParametrosStreaming: async function ({ commit, state }, datos){ 
      try{
        const settings = {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'parametrosstreaming/'+datos.id;
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
    },

    /*Ventas EPS*/ 
    ConsultarVentasEps: async function ({ commit, state }, datos){
      try{
        const settings = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        if(!!datos){
          var url = rutas.backend+'ventaseps/'+datos;
        }else{
          var url = rutas.backend+'ventaseps/';
        }
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LlenarVentasEps', json);
        }
      }catch(err){
        console.log(err)
      }
    },

    SuspenderCuentaEps: async function ({ commit, state }, datos){
      try{
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        var url = rutas.backend+'ventaseps/suspender';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
    },
    
    /*Ventas*/ 
    ConsultarVentas: async function ({ commit, state }, datos){
      try{
        const settings = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        if(!!datos){
          var url = rutas.backend+'ventas/'+datos;
        }else{
          var url = rutas.backend+'ventas/';
        }
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LlenarVentas', json);
        }
      }catch(err){
        console.log(err)
      }
    },

    SuspenderCuentaStreaming: async function ({ commit, state }, datos){
      try{
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        var url = rutas.backend+'ventas/suspender';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
    },

    /*Recargas*/ 
    ConsultarRecargas: async function ({ commit, state }){
      try{
        const settings = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
        };
        const url = rutas.backend+'recargas/';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LlenarRecargas', json);
        }
      }catch(err){
        console.log(err)
      }
    },

    ConciliarRecarga: async function ({ commit, state }, datos){
      try{
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'recargas/conciliar';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LLenarAlerta', json)
        }
      }catch(err){
        console.log(err)
      }
    },

    /*Venta Streaming*/
    ObtenerPlataformasStreaming: async function ({ commit, state }){ 
      try{
        const settings = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': state.barerAuth,
            'x-access-token': state.token
          }
        };
        const url = rutas.backend+'ventastreaming/plataformasstreaming';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LlenarPlataformasStreaming', json);
        }
      } catch(err){
        console.log(err);
      }
    },

    ObtenerDuracionStreaming: async function ({ commit, state }, datos){ 
      try{
          const settings = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
            },
            body: JSON.stringify(datos)
          };
          const url = rutas.backend+'ventastreaming/duracionstreaming';
          const data = await fetch(url, settings);
          const json = await data.json();
          if(typeof json.error.expiredAt !== 'undefined'){
            commit('PopUPTokenexpired');
          }else{
            commit('LlenarDuracionStreaming', json);
          }
      }catch(err){
        console.log(err)
      }
    },
  
    ObtenerTipodeCuentaStreaming: async function ({ commit, state }, datos){ 
        try{
          const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': state.barerAuth,
                'x-access-token': state.token
            },
            body: JSON.stringify(datos)
          };
          const url = rutas.backend+'ventastreaming/tipodecuentastreaming';
          const data = await fetch(url, settings);
          const json = await data.json();
          if(typeof json.error.expiredAt !== 'undefined'){
            commit('PopUPTokenexpired');
          }else{
            commit('LlenarTipodeCuentaStreaming', json);
          }
      }catch(err){
        console.log(err)
      }
    },

    ObtenerClienteCelular: async function ({ commit, state }, datos){ 
      try{
        commit('VisualizarProgressCircular');
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'ventastreaming/consultarcelularcliente';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
            commit('PopUPTokenexpired');
        }else{
          commit('LlenarClienteCelular', json);
        }
        commit('OcultarProgressCircular'); 
      }catch(err){
        console.log(err)
      }
    },

    ObtenerPreciosStreaming: async function ({ commit, state }, datos){ 
      try{
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'ventastreaming/preciosstreaming';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
            commit('PopUPTokenexpired');
        }else{
          commit('LlenarPreciosStreaming', json);
        }
      }catch(err){
        console.log(err)
      }
    },

    ConsultarDatosStreaming: async function ({ commit, state }, datos){ 
      try{
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'ventastreaming/datosstreaming';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
            commit('PopUPTokenexpired');
        }else{
          commit('LlenarDatosStreaming', json);
        }
      }catch(err){
        console.log(err)
      }
    },

    VenderStreaming: async function ({ dispatch, commit, state }, datos){
      try{
        commit('VisualizarProgressCircular');
        const settings = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': state.barerAuth,
              'x-access-token': state.token
          },
          body: JSON.stringify(datos)
        };
        const url = rutas.backend+'ventastreaming/ventastreaming';
        const data = await fetch(url, settings);
        const json = await data.json();
        if(typeof json.error.expiredAt !== 'undefined'){
          commit('PopUPTokenexpired');
        }else{
          commit('LlenarVenderStreaming', json);
          setTimeout(() => { dispatch('ConsultarPuntoDeVenta') }, 500);
        }
        commit('OcultarProgressCircular');
      }catch(err){
        console.log(err)
      }
    },

  },

  modules: {
    billeteras,
  }
})