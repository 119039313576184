<template>
    <div>

        <v-dialog
          v-model="Alerta.visible"
          persistent
          max-width="600"
        >
          <v-card>
             <v-toolbar
              :color="Alerta.error ? 'error' : 'success'"
              dark
            >
              ALERTA

              <v-spacer>
                
              </v-spacer>
              <v-icon
                v-if="Alerta.error"
                color="white"
                right
              >
               mdi-cancel
              </v-icon>

              <v-icon
                v-else
                color="white"
                right
              >
               mdi-checkbox-marked-circle
              </v-icon>

            </v-toolbar>

            <div class="text-h4 pa-12">{{Alerta.mje}}

            </div>


            <v-divider></v-divider>

            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn color="error" @click="OcultarAlerta" requerid>Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
<!--
        <v-snackbar
          v-model="Alerta.visible"
          :color="Alerta.error ? 'error' : 'success'"
        >
         {{Alerta.mje}}
        </v-snackbar>
-->
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
export default ({

    watch:{
      Alerta: function(value){
        setTimeout( () => {this.OcultarAlerta()}, value.tiempo)
      },
    },

    methods: {
      ...mapMutations(['OcultarAlerta']),
    },

    computed: {
      ...mapState(['Alerta']),
    }

})

</script>