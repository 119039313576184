<template>
    <div v-if="token">
        <Navup/>
        <Navleft/>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Navup from '@/components/nav/Navup';
import Navleft from '@/components/nav/Navleft';

export default {
    components: {
        Navup,
        Navleft
    },

    data: function() {
        return{
            
        }
    },

     computed:{
      ...mapState(['token'])
    }
}


</script>