import rutas from "@/config.js"

export default{
    namespaced: true,
    
    state: {
        RetirosQR: [],

        Transferencias: [],

        Mensaje: {status: 500, mje: '', error: true, id_transaccion:0},

        MediosPago:[
            {id: 1, titulo:'1. Nequi'},
            {id: 2, titulo:'2. Daviplata'},
            {id: 3, titulo:'3. Bancolombia'},
          ],

          Destino:[
            {id: 1, titulo:'1. Nequi'},
            {id: 2, titulo:'2. Movii'},
            {id: 3, titulo:'3. Bancolombia'},
            {id: 4, titulo:'4. Ahorro a la mano'},
            {id: 5, titulo:'5. Daviplata'},
            {id: 6, titulo:'6. Davivienda'},
            {id: 7, titulo:'7. Rappipay'},
            {id: 8, titulo:'8. Banco Bogota'},
          ],

          Ventas: [],
    },

    getters: {
        RetirosQR(state){
            return state.RetirosQR
        },

        Transferencias(state){
          return state.Transferencias
      },

        MediosPago(state){
            return state.MediosPago
        },

        Destino(state){
          return state.Destino
      },
        
        Mensaje(state){
          return state.Mensaje
        },

        VentasGetter(state){
          return state.Ventas
        },
    },

    mutations: {
        LlenarRetirosQR(state, data){
            state.RetirosQR = data;
        },

        LlenarTransferencias(state, data){
          state.Transferencias = data;
      },

        LLenarMensaje(state, data){
            state.Mensaje = data;
        },

        LlenarVentas(state, data){
          state.Ventas = data
        },
    },

    actions: {
        ConsultarRetirosQR: async function ({ rootState , commit }){
            try{
                const settings = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': rootState.barerAuth,
                        'x-access-token': rootState.token
                    },
                  };
                  const url = rutas.backend+'billetera/retirosqr';
                  const data = await fetch(url, settings);
                  const json = await data.json();
                  if(typeof json.error.expiredAt !== 'undefined'){
                    commit('PopUPTokenexpired', null, { root: true });
                  }else{
                    commit('LlenarRetirosQR', json);
                  }
            }catch(err){
                console.log(err)
            }
        },

        ConsultarTransferencias: async function ({ rootState , commit }){
          try{
              const settings = {
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': rootState.barerAuth,
                      'x-access-token': rootState.token
                  },
                };
                const url = rutas.backend+'billetera/transferencias';
                const data = await fetch(url, settings);
                const json = await data.json();
                if(typeof json.error.expiredAt !== 'undefined'){
                  commit('PopUPTokenexpired', null, { root: true });
                }else{
                  commit('LlenarTransferencias', json);
                }
          }catch(err){
              console.log(err)
          }
      },

        NuevoRetirosQR: async function ({ rootState, dispatch, commit }, datos){
            try{
              const settings = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': rootState.barerAuth,
                    'x-access-token': rootState.token
                },
                body: JSON.stringify(datos)
              };
      
              const url = rutas.backend+'billetera/nuevo-retiroqr';
              const data = await fetch(url, settings);
              const json = await data.json();
              
              if(typeof json.error.expiredAt !== 'undefined'){
                commit('PopUPTokenexpired', null, { root: true });
              }else{
                commit('LLenarAlerta', json, { root: true })
                commit('LLenarMensaje', json)
              }
            }catch(err){
              console.log(err)
            }
          },

          NuevoTransferencia: async function ({ rootState, dispatch, commit }, datos){
            try{
              const settings = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': rootState.barerAuth,
                    'x-access-token': rootState.token
                },
                body: JSON.stringify(datos)
              };

              const url = rutas.backend+'billetera/nuevo-transferencia';
              const data = await fetch(url, settings);
              const json = await data.json();
              
              if(typeof json.error.expiredAt !== 'undefined'){
                commit('PopUPTokenexpired', null, { root: true });
              }else{
                commit('LLenarAlerta', json, { root: true })
                commit('LLenarMensaje', json)
              }
            }catch(err){
              console.log(err)
            }
          },

          ConsultarVentas: async function ({ commit, rootState }, datos){
            try{
              const settings = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': rootState.barerAuth,
                    'x-access-token': rootState.token
                },
              };
              if(!!datos){
                var url = rutas.backend+'billetera/'+datos;
              }else{
                var url = rutas.backend+'billetera/ventas';
              }
              const data = await fetch(url, settings);
              const json = await data.json();
              if(typeof json.error.expiredAt !== 'undefined'){
                commit('PopUPTokenexpired', null, { root: true });
              }else{
                commit('LlenarVentas', json);
              }
            }catch(err){
              console.log(err)
            }
          },

          Conciliar: async function ({ commit, rootState }, datos){
            try{
              const settings = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': rootState.barerAuth,
                    'x-access-token': rootState.token
                },
                body: JSON.stringify(datos)
              };
              var url = rutas.backend+'billetera/conciliar';
              const data = await fetch(url, settings);
              const json = await data.json();
              if(typeof json.error.expiredAt !== 'undefined'){
                commit('PopUPTokenexpired', null, { root: true });
              }else{
                commit('LLenarAlerta', json, { root: true })
              }
            }catch(err){
              console.log(err)
            }
          },
      
    },

}