<template>
  <v-footer padless app v-if="usuarioAutenticado">
    <v-col
      class="text-center"
      cols="12"
    >
      <strong>
      <a style = "text-decoration: None" class="black--text" href="https://www.kolbe.website" target="_blank">
         DILIBIT v1.3 - KOLBE SOLUCIONES WEB</a>
      </strong>
    </v-col>
  </v-footer>
</template>

<script>
import { mapGetters } from 'vuex'

    export default {
      computed:{
              ...mapGetters(['usuarioAutenticado'])
      }
    }
</script>
