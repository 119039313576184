<template>
<div>
    <v-navigation-drawer
      app
      :value="Navdrawer"
      v-if="usuarioAutenticado"
      class="mx-auto"
      width="407"
    >

      <v-list-item>
        <v-list-item-avatar>
          <v-img src="@/assets/perfil.png"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title  @click="home()">Menú Principal</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

     <!---->
      <v-list>
      <v-list-group
        v-for="(item, index1) in Menu.menu" :key="index1"
      >
        <template v-slot:activator>
          <v-list-item-title>
            {{ index1+1 }} {{ item.titulo }}
           <v-chip
                  v-if="item.nuevo == 1"
                  class="ma-1"
                  color="red"
                  text-color="red"
                  small
                  outlined
                >
                  nuevo
                  <v-icon right x-small>
                    mdi-fire
                  </v-icon>
                </v-chip>
          </v-list-item-title>
        </template>

        <v-list-group
          no-action
          sub-group
          v-for="(item2, index2) in item.menu2" :key="index2"
        >

          <template v-show="item2.activo == '1'" v-slot:activator>
            
              <v-list-item-title @click="ruta(item2.ruta)">
                {{index1+1}}.{{index2+1}} {{ item2.titulo }}
                
                <v-chip
                  v-if="item2.nuevo == 1"
                  class="ma-1"
                  color="red"
                  text-color="red"
                  small
                  outlined
                >
                  nuevo
                  <v-icon right x-small>
                    mdi-fire
                  </v-icon>
                </v-chip>

                <v-chip
                  v-if="item2.proximamente == 1"
                  class="ma-1"
                  color="Morado"
                  text-color="Morado"
                  small
                  outlined
                >
                  próximamente
                  <v-icon right x-small>
                    mdi-star
                  </v-icon>
                </v-chip>

              </v-list-item-title>
            
          </template>

          <v-list-item
            v-show="item.activo == '1'"
            link
             v-for="(item3, index3) in item2.menu3" :key="index3"
          >
            <v-list-item-title
              v-show="item3.activo == '1'"
              @click="ruta(item3.ruta)"
            >
              {{index1+1}}.{{index2+1}}.{{index3+1}} {{ item3.titulo }}

              <v-chip
                  v-if="item3.nuevo == 1"
                  class="ma-1"
                  color="red"
                  text-color="red"
                  small
                  outlined
                >
                  nuevo
                  <v-icon right x-small>
                    mdi-fire
                  </v-icon>
                </v-chip>

                <v-chip
                  v-if="item3.proximamente == 1"
                  class="ma-1"
                  color="Morado"
                  text-color="Morado"
                  small
                  outlined
                >
                  próximamente
                  <v-icon right x-small>
                    mdi-star
                  </v-icon>
                </v-chip>

            </v-list-item-title>

          </v-list-item>
        </v-list-group>

      </v-list-group>
    </v-list>
     <!---->
    </v-navigation-drawer>
</div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions} from 'vuex'

    export default {

      created(){
        this.ConsultarMenu();
      },

      methods: {
        ...mapMutations(['CambiarNavdrawer']),
        ...mapActions(['ConsultarMenu']),

        home () {
          this.$router.push('/').catch(()=>{});
        },

        ruta(route){
          this.$router.push(route).catch(()=>{});
          this.CambiarNavdrawer
        }
      },

      computed:{
        ...mapState(['Menu']),
        ...mapGetters(['usuarioAutenticado', 'ValorNavdrawer']),

        Navdrawer(){
          return !!this.ValorNavdrawer
        }
      }
       
    };
</script>