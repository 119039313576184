import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/streaming',
    name: 'Streaming',
    component: () => import('../views/Streaming.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: () => import('../views/Usuarios.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/puntosdeventa',
    name: 'PuntosDeVenta',
    component: () => import('../views/PuntosDeVenta.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: () => import('../views/Clientes.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/productosstreaming',
    name: 'ProductosStreaming',
    component: () => import('../views/StreamingProductos.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/codigosstreaming',
    name: 'CodigosStreaming',
    component: () => import('../views/StreamingCodigos.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/parametrosstreaming',
    name: 'ParametrosStreaming',
    component: () => import('../views/StreamingParametros.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/notificaciones',
    name: 'Notificaciones',
    component: () => import('../views/Notificaciones.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/ventas/:id',
    name: 'Ventas',
    component: () => import('../views/Ventas.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/recargas',
    name: 'Recargas',
    component: () => import('../views/Recargas.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/redafiliados',
    name: 'RedAfiliados',
    component: () => import('../views/RedAfiliados.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/certificados',
    name: 'Certificados',
    component: () => import('../views/Certificados.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/epsafiliacion',
    name: 'EpsAfiliacion',
    component: () => import('../views/Tramites/EpsAfiliacion.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/epsrecaudo',
    name: 'EpsRecaudo',
    component: () => import('../views/Tramites/EpsRecaudo.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/parametroseps',
    name: 'EpsParametros',
    component: () => import('../views/EpsParametros.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/ventasseguridadsocial/',
    name: 'VentasSeguridadSocial',
    component: () => import('../views/VentasEps.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/certificadoinmuebles/',
    name: 'CertificadoInmuebles',
    component: () => import('../views/Tramites/CertificadoInmuebles.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/certificadovehiculos/',
    name: 'Certificadovehiculos',
    component: () => import('../views/Tramites/CertificadoVehiculos.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/retirosqr/',
    name: 'RetirosQR',
    component: () => import('../views/Tramites/Billetera/RetirosQR.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/transferencias/',
    name: 'Transferencias',
    component: () => import('../views/Tramites/Billetera/Transferencias.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/ventasbilletera/',
    name: 'VentasBilletera',
    component: () => import('../views/Tramites/Billetera/Ventas.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: "/404",
    component: () => import('../views/404.vue'),
  },
  { 
    path: '*',
    redirect: '/404'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (store.state.token == null && to.matched.some(record => record.meta.requiresAuth)) next({ name: 'Login' })
  else next()
})

export default router