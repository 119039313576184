<template>
<div>
  <v-app>  
    <v-main>
      <Nav/>
      <Alerts/>
      <Dialogs/>
      <!--
      <ProgressCircular v-if="ProgressCircular == true" />
      -->
      <v-container>
          <router-view/>
      </v-container>
      <Footer/>
    </v-main>
  </v-app>
 </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Nav from './views/Nav';
import Footer from './views/Footer';
import Alerts from './components/Alertas/Alerts'
import Dialogs from './components/Alertas/Dialogs'
//import ProgressCircular from './components/Alertas/ProgressCircular'

export default {
  name: 'App',

  components: {
    Nav,
    Footer,
    Alerts,
    Dialogs
  },

  created() {
    this.LeerToken
  },

  data: () => ({
       
  }),

  methods: {
    ...mapActions(['LeerToken'])
  },

   computed: {
      ...mapState(['ProgressCircular']),
    }
};
</script>
